import React, {useState, useEffect} from 'react'
import Button from '../components/Button'
import Card from '../components/CustomCard'
import ProductCard from '../components/ProductCard'
// import TeamMember from '../components/TeamCard'
// import Input from '../components/Input'
// import TextArea from '../components/TextArea'
import LastPosts from '../components/LastPosts'
import Form from '../components/BasicForm'
import {Helmet} from 'react-helmet'
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'

let currentProduct = 0
let currentIntroImage = 0
let customerLogos = [{
	image: 'https://cdn01.prd.pentcloud.com/site/Logos/9.svg',
	label: 'Al Grano',
	description: 'Al Grano, es una tienda de productos a granel 100% Guatemalteca que no utiliza plástico. Ellos crearon su tienda en línea en MarketSpace logrando así un acercamiento de sus clientes hacia sus productos.',
	link:{
		to: '/',
		label: 'Ver proyecto',
		type: 'link',
		color: 'bg-green-900'
	}
// },{
// 	image: 'https://cdn01.prd.pentcloud.com/site/Logos/1.jpg',
// 	label: 'Claro',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl elit, porttitor sit amet eleifend ut, fermentum id nisi. Integer venenatis sed sem sit amet placerat. Orci varius natoque penatibus et magnis dis parturient.',
// 	link:{
// 		to: '/',
// 		label: 'Ver proyecto',
// 		type: 'link',
// 		color: 'bg-green-900'
// 	}
// },{
	// image: 'https://cdn01.prd.pentcloud.com/site/Logos/2.jpg',
	// label: 'GPO Vallas',
	// description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl elit, porttitor sit amet eleifend ut, fermentum id nisi. Integer venenatis sed sem sit amet placerat. Orci varius natoque penatibus et magnis dis parturient.',
	// link:{
	// 	to: '/',
	// 	label: 'Ver proyecto',
	// 	type: 'link',
	// 	color: 'bg-green-900'
	// }
// },{
// 	image: 'https://cdn01.prd.pentcloud.com/site/Logos/3.svg',
// 	label: 'Al Grano',
// 	description: 'Al Grano, es una tienda de productos a granel 100% Guatemalteca que no utiliza plástico. Ellos crearon su tienda en línea en MarketSpace logrando así un acercamiento de sus clientes hacia sus productos.',
// 	link:{
// 		to: '/',
// 		label: 'Ver proyecto',
// 		type: 'link',
// 		color: 'bg-green-900'
// 	}
// },{
	// image: 'https://cdn01.prd.pentcloud.com/site/Logos/4.jpg',
	// label: 'Sytesa',
	// description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl elit, porttitor sit amet eleifend ut, fermentum id nisi. Integer venenatis sed sem sit amet placerat. Orci varius natoque penatibus et magnis dis parturient.',
	// link:{
	// 	to: '/',
	// 	label: 'Ver proyecto',
	// 	type: 'link',
	// 	color: 'bg-green-900'
	// }
// },{
// 	image: 'https://cdn01.prd.pentcloud.com/site/Logos/5.jpg',
// 	label: 'Al Grano',
// 	description: 'Al Grano, es una tienda de productos a granel 100% Guatemalteca que no utiliza plástico. Ellos crearon su tienda en línea en MarketSpace logrando así un acercamiento de sus clientes hacia sus productos.',
// 	link:{
// 		to: '/',
// 		label: 'Ver proyecto',
// 		type: 'link',
// 		color: 'bg-green-900'
// 	}
},{
	image: 'https://cdn01.prd.pentcloud.com/site/Logos/6.jpg',
	label: 'Grupo Unipharm',
	description: 'Nuestra plataforma ha logrado automatizar las capacitaciones y las certificaciones a tal nivel que han ahorrado miles de dólares en viáticos y otros costos involucrados en el proceso.',
	link:{
		to: '/',
		label: 'Ver proyecto',
		type: 'link',
		color: 'bg-green-900'
	}
// },{
// 	image: 'https://cdn01.prd.pentcloud.com/site/Logos/7.jpg',
// 	label: 'Rotoplas',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl elit, porttitor sit amet eleifend ut, fermentum id nisi. Integer venenatis sed sem sit amet placerat. Orci varius natoque penatibus et magnis dis parturient.',
// 	link:{
// 		to: '/',
// 		label: 'Ver proyecto',
// 		type: 'link',
// 		color: 'bg-green-900'
// 	}
},{
	image: 'https://cdn01.prd.pentcloud.com/site/Logos/8.jpg',
	label: 'GES Operador Logístico',
	description: 'Automatizamos sus procesos y flujos de trabajo, con una solución que los ayuda a ver que sucede con recursos en donde se capturan los datos y obtienen reportes de manera inmediata.',
	link:{
		to: '/',
		label: 'Ver proyecto',
		type: 'link',
		color: 'bg-green-900'
	}
},]

let products = [{
	title: 'SpaceSuite',
	description: 'Mejora tu productividad, lleva el control del día a día en el trabajo y dedica tiempo valioso a tareas que requieran una atención especial.',
	image: 'https://cdn01.prd.pentcloud.com/site/Medio/2.jpg',
	link:{
		to: '/',
		label: 'Conoce más',
		type: 'link'
	}
},{
	title: 'MarketSpace',
	description: 'Vender en internet no tiene que ser tan difícil y con MarketSpace tendrás tu propia tienda en línea con la que podrás empezar a vender en minutos.',
	image: 'https://cdn01.prd.pentcloud.com/site/Medio/1.jpg',
	link:{
		to: '/',
		label: 'Conoce más',
		type: 'link'
	}
},{
	title: 'Learning',
	description: 'Aumenta la productividad de tu equipo de trabajo capacitando a  tus empleados desde cualquier lugar con cursos, videos, presentaciones y mucho más.',
	image: 'https://cdn01.prd.pentcloud.com/site/Medio/3.jpg',
	link:{
		to: '/',
		label: 'Conoce más',
		type: 'link'
	}
}]

// let team = [{
// 	name: 'Anahizabel Dubón',
// 	job: 'Senior UX Designer',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// },{
// 	name: 'Pedro Trinidad',
// 	job: 'Senior UI Designer',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// },{
// 	name: 'Juan Acabal',
// 	job: 'Senior Fullstack Developer',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// },{
// 	name: 'Anthony Cifuentes',
// 	job: 'Senior Mobile Developer',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// },{
// 	name: 'William Chanchavac',
// 	job: 'Senior Fullstack Developer',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// },{
// 	name: 'Diego Cornejo',
// 	job: 'CEO',
// 	description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
// 	page: '/',
// 	links: [{
// 		site: 'facebook',
// 		url: 'https://www.facebook.com'
// 	}]
// }]

let formRef = React.createRef()

let introImages = [
	'https://cdn01.prd.pentcloud.com/site/Header/1.jpg',
	'https://cdn01.prd.pentcloud.com/site/Header/2.jpg',
	'https://cdn01.prd.pentcloud.com/site/Header/3.jpg',
	'https://cdn01.prd.pentcloud.com/site/Header/4.jpg',
]

const scrollProducts = (action) => {
	if (action === 'right') {
		if(currentProduct < products.length - 1){
			currentProduct++;
			products[currentProduct].ref.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
		}
	} else if (action === 'left') {
		if(currentProduct > 0){
			currentProduct--;
			products[currentProduct].ref.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
		}
	}
}
	
const scrollTo = (ref) => {
	ref.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
}


let checkWindow = setInterval(() => {
	if(typeof window !== `undefined`){
		clearInterval(checkWindow);
		if (window.location.host === 'marketspace.digital') {
			console.log(window)
			window.location.href = '/marketspace'
		}
	}
}, 50)

console.log(checkWindow)

export default function Index() {
	
	const [introImage, setIntroImage] = useState(introImages[0])
	useEffect(() => {
		setTimeout(() => {
			if (currentIntroImage >= introImages.length -1) {
				currentIntroImage = 0
			}else{
				currentIntroImage++
			}
			// console.log(currentIntroImage)
			setIntroImage(introImages[currentIntroImage]); 
		},5000)
	})

	return(
		<div>
			<Helmet>
				<title>Pentcloud | Obtén herramientas digitales de clase mundial hechas a tu medida. Mejora tu productividad, vende en internet, aumenta el conocimiento de tus colaboradores, hazlo fácil con nuestras soluciones.</title>
			</Helmet>
			<div className="intro-container bg-ornament bg-right bg-auto bg-no-repeat">
				<div className="container mx-auto grid lg:grid-cols-3 lg:px-0 grid-cols-1 px-12 gap-12 md:gap-0 lg:mb-12 justify-center h-full">
					<div className="col-span-1 lg:col-span-2 flex flex-col justify-center lg:pl-12 md:px-12">
						<h1 className="text-5xl md:text-6xl font-bold text-gray mb-8 mr-12">
							Obtén herramientas de clase mundial hechas a tu medida
						</h1>
						<span className="lg:text-gray-400 text-gray text-lg mb-12 mr-12">
							En Pentcloud nos especializamos en diseñar, construir, y entregar productos digitales que resuelven problemas de forma eficiente, escalable y agradables para el usuario. ¿Quieres saber más?
						</span>
						<div className="flex flex-row lg:justify-start justify-center">
							<Button classes="px-8 py-4 bg-green-900 text-white rounded-lg mr-8" onClick={() => scrollTo(formRef)}>
								Contáctanos ahora
							</Button>
							<AniLink paintDrip hex="#FFFFFF" to="/soluciones">
							<Button classes="px-8 py-4 bg-white border-2 border-green-900 text-green-900 rounded-lg">
								Nuestro trabajo
							</Button>
							</AniLink>
						</div>
					</div>
					<div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
						{/* <StaticImage src={introImage} className="rounded-3xl shadow-xl transition ease-in-out duration-300" quality="100" alt="Pentcloud"/> */}

						<img src={introImage} width="504px" alt="Pentcloud" className="rounded-3xl shadow-xl transition ease-in-out duration-300" />
						{/* <div className="group col-span-1 row-span-1 rounded-3xl relative box-border bg-cover bg-clip-content bg-center bg-no-repeat rounded-3xl shadow-xl transition ease-in-out duration-300" style={{backgroundImage: 'url(' + (introImage) + ')', width: '504px', height: '504px'}}></div> */}

					</div>
				</div>
			</div>
			<div className="bg-gradient-to-b from-white to-gray-100 py-12">
				<div className="container mx-auto">
					{/* <div className="py-12 flex flex-col justify-center content-center text-center">
						<span className="text-2xl font-bold text-gray tracking-widest">
							NUESTROS CLIENTES
						</span>
					</div>
					<div className="mb-12 flex flex-row justify-evenly content-center text-center">
						{
							customerLogos.map((customer, i) =>
								<img key={i} src={'/images/Company' +  (i+1) +  '.svg'} alt={customer.label} className="p-4 customer-logo" />
							)
						}
					</div> */}
					<div className="md:p-12 p-6 grid md:grid-cols-2 grid-cols-3 gap-4">
						<div className="sm:pl-0 md:pl-12 lg:pl-24 md:col-span-1 col-span-2 flex flex-row justify-end">
							<h2 className="text-5xl text-left font-bold text-gray">
							Expertos en cada parte del desarrollo
							</h2>
						</div>
						<div className="pr-0 md:pl-12 lg:pr-24 col-span-1 flex flex-row justify-start items-center">
							<span className="text-gray-400 text-base">
								Damos a nuestros clientes el talento creativo, técnico y empresarial que necesitan para triunfar y cumplir sus objetivos.
							</span>
						</div>
					</div>
				</div>
				<div className="bg-ornament-green bg-right-bottom bg-no-repeat bg-team-size">
					<div className="container mx-auto grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-2 gap-12 grid-flow-row md:px-12 px-6 py-20">
							<Card title='01' subtitle='Brainstorming' link={{ to: '/', label: 'Conoce más' }} >
								Obtenemos un conocimiento profundo de la idea de tu producto y el valor comercial del proyecto. Esto nos permitirá presentarte una oferta personalizada para el proyecto.
							</Card>
							<Card title='02' subtitle='Puesta en marcha' link={{to: '/', label: 'Conoce más'}} titleColor='text-gray-300' >
								Entendemos todas tus necesidades y las expectativas de los usuarios, y estamos listos para comenzar con el diseño y el desarrollo.
							</Card>
							<Card title='03' subtitle='Diseño y desarrollo' link={{ to: '/', label: 'Conoce más' }} titleColor='text-gray-300' >
								En esta fase empezamos a trabajar en tus ideas. Podemos ayudarte con el desarrollo de software, DevOps, QA, así como con el diseño visual.
							</Card>
							<Card title='04' subtitle='Soporte' link={{ to: '/', label: 'Conoce más' }} titleColor='text-gray-300' >
								El proyecto puede estar terminado, pero nos aseguramos de su calidad contínua. Te apoyaremos en mejoras incrementales.
							</Card>
					</div>
				</div>
				{/* <div className="container mx-auto">
					<div className="py-24 md:px-20 px-12 flex flex-col justify-center content-center text-center">
						<h2 className="text-5xl font-bold text-gray">
							En qué somos expertos
						</h2>
						<div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-12 grid-flow-row lg:px-12 py-16 px-0 gap-4">
							<Card icon="/images/Heart.svg" subtitle='Research' link={{ to: '/', label: 'Conoce más', type: 'button' }} >
								Si creemos que es necesario realizar una investigación detallada, haremos una investigación para entender a detalle todas las necesidades.
							</Card>

							<Card icon="/images/Heart.svg" subtitle='UI/UX Design' link={{ to: '/', label: 'Conoce más', type: 'button' }} titleColor='text-gray-300' >
								Investigación y el diseño de experiencias web y móviles complejas hasta la ilustración.
							</Card>

							<Card icon="/images/Heart.svg" subtitle='Development' link={{ to: '/', label: 'Conoce más', type: 'button' }} titleColor='text-gray-300' >
								Creamos soluciones que son funcionales, efectivas y atractivas para sus usuarios finales.
							</Card>
						</div>
					</div>
				</div> */}
			</div>
			<div className="bg-medio-break bg-fixed md:bg-right relative">
				<div className="h-full w-full absolute bg-gray-900 z-0 opacity-50"></div>
				<div className="container mx-auto py-24">
					<div className="grid grid-cols-4">
						<div className="lg:col-span-3 col-span-4 px-12 flex flex-row justify-end">
							<h2 className="text-6xl lg:text-gray-300 text-white font-bold leading-snug" style={{zIndex: 1}}>
								Te proveemos con soluciones de clase mundial para que puedas alcanzar tus objetivos
							</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="xl:p-40 lg:px-20 px-12 lg:mt-0 mt-24">
				<div className="container mx-auto">
					<div className="flex flex-row justify-between">
						<div className="md:px-12 px-0 md:pr-12">
							<h2 className="text-5xl text-gray font-bold">
								Administra tu trabajo diario
							</h2>
						</div>
						<div className="md:px-12 flex flex-row px-0">
							<Button classes="bg-white p-4 w-12 h-12 self-end rounded-xl transition duration-100 ease-in-out mr-6" onClick={() => scrollProducts('left')}>
								<img src="/images/ArrowLeftBlack.svg" className="w-4 h-4" alt=""/>
							</Button>
							<Button classes="bg-green-900 p-4 w-12 h-12 self-end rounded-xl transition duration-100 ease-in-out" onClick={() => scrollProducts('right')}>
								<img src="/images/ArrowRight.svg" className="w-4 h-4" alt=""/>
							</Button>
						</div>
					</div>
					<div className="flex flex-row flex-nowrap md:px-12 overflow-hidden">
							{products.map((product, i) =>
									<div className="flex-none lg:w-3/4 w-full py-12 mr-12 last:m-0" ref={ref => products[i].ref = ref} key={i}>
										<ProductCard title={product.title} image={product.image}>
											{product.description}
										</ProductCard>
									</div>
								)
							}
					</div>
				</div>
			</div>
			<div className="bg-gradient-to-b from-white to-gray-100 pb-16">
				<div className="container mx-auto">
					<div className="text-center">
						<h2 className="text-5xl text-gray font-bold leading-snug px-12 md:px-0">
							Algunos de nuestros<br/>clientes y partners
						</h2>
					</div>
					<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-24 md:gap-12 grid-flow-row py-24 px-12">
						{
							customerLogos.map((customer, i) =>
								// (i < 3) &&
								<Card {...customer}  key={i}>
									{customer.description}
								</Card>
							)
						}
					</div>
				</div>
			</div>
			{/* <div className="bg-gradient-to-b from-gray-100 via-white to-white px-12">
				<div className="container mx-auto">
					<div className="text-center py-12">
						<h2 className="text-5xl text-gray font-bold leading-snug mb-4">
							Conoce a nuestro equipo
						</h2>
						<span className="text-center text-sm text-gray-400">
							Damos a nuestros clientes el talento de un equipo creativo, técnico y empresarial que necesitan para triunfar y cumplir sus objetivos.
						</span>
					</div>
					<div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-8 grid-flow-row">
						{
							team.map((member, i) =>
								<TeamMember name={member.name} job={member.job} description={member.description} page={member.page} key={i} />
							)
						}
					</div>
				</div>
			</div> */}
			<div className="my-40 px-12" ref={formRef} id="mainForm">
				<div className="container mx-auto">
					<Form />
				</div>
			</div>
			<div className="px-12">
				<div className="container mx-auto">
					<LastPosts />
				</div>
			</div>
		</div>
	)
}
