import React from 'react'
import { Link } from 'gatsby'
import Button from './Button'

let titleColor = 'text-blue-900'

const CustomCard = (props) => {
	return <div className={'group col-span-1 hover:bg-white first:bg-white bg-gray-100 rounded-3xl flex flex-col justify-between ' + (props.title ? 'p-8' : 'p-4')}>
	{ props.title &&
		<h1 className={(props.titleColor || titleColor) + ' font-bold text-7xl group-hover:text-blue-900 transition duration-100 ease-in-out'}>
		{props.title}
		</h1>
	}
	<div className="flex flex-row justify-start items-center my-4">
		{props.icon &&
			<div className="bg-blue-900 p-2 mr-2 rounded-lg">
				<img src={props.icon} className="w-4 h-4" alt={props.subtitle} />
			</div>
		}
		{ props.subtitle &&
			<h2 className="text-2xl text-gray font-bold">
				{props.subtitle}
			</h2>
		}
		{props.image &&
			<img src={props.image} alt={props.label} className="p-4 customer-logo" />
		}
	</div>
	<span className="text-sm text-gray-400 mb-4">
		{props.children}
	</span>
	{/* {props.link.type === 'button'
		?
			<Button classes="bg-green-900 -mr-4 -mb-4 p-4 w-12 h-12 self-end rounded-br-3xl invisible group-hover:visible transition duration-100 ease-in-out">
				<img src="/images/ArrowRight.svg" className="w-4 h-4" alt=""/>
			</Button>
		:
		<Link to={props.link.to}>
			<span className="text-sm font-gray">
				{props.link.label}
			</span>
			<div className={"h-1 w-16 bg-transparent group-hover:" +  (props.link.color || "bg-orange") + " transition duration-100 ease-in-out"}>
			</div>
		</Link>
	} */}
</div>
}

export default CustomCard