import React from 'react'
// import { Link } from 'gatsby'
// import Button from './Button'

const defaultBg = '/images/SpaceSuite.png'

const ProductCard = (props) =>
<div className="w-full rounded-3xl relative box-border bg-cover" style={{height:'520px', backgroundImage: 'url(' + (props.image || defaultBg) + ')'}}>
	<div className="h-full w-full absolute bg-gradient-to-b to-gray-100 from-gray-800 z-0 rounded-3xl opacity-50">

	</div>
	<div className="p-12 relative w-1/2	">
		<h3 className="text-4xl text-white font-bold mb-4">
			{props.title}
		</h3>
		<span className="text-sm text-white">
			{props.children}
		</span>
	</div>
</div>

export default ProductCard