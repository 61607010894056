import React from "react"
import { Link } from 'gatsby'

let link = '/'

const LastPost = (props) => {
	return (
		<div>
			<div className="text-center py-12">
				<h2 className="text-5xl text-gray font-bold leading-snug mb-4">
					Las últimas noticias
				</h2>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-12 grid-flow-row items-center">
				<div className="col-span-1 p-8 flex flex-col justify-end align-start rounded-3xl bg-gradient-to-b from-gray-300 to-gray-800 h-80">
					<span className="text-sm text-white">
						31 Enero 2021
					</span>
					<h3 className="text-2xl text-white font-bold">
						8 reglas generales en Diseño UI
					</h3>
					<Link to={link} activeClassName="font-bold text-white" className="text-blue-900 text-sm">
						Conoce más
					</Link>
				</div>
				<div className="col-span-1 p-12 flex flex-col justify-end align-start rounded-3xl bg-gradient-to-b from-gray-300 to-gray-800 h-96">
					<span className="text-sm text-white">
						31 Enero 2021
					</span>
					<h3 className="text-2xl text-white font-bold">
						8 reglas generales en Diseño UI
					</h3>
					<Link to={link} activeClassName="font-bold text-white" className="text-blue-900 text-sm">
						Conoce más
					</Link>
				</div>
				<div className="col-span-1 p-12 flex flex-col justify-end align-start rounded-3xl bg-gradient-to-b from-gray-300 to-gray-800 h-80">
					<span className="text-sm text-white">
						31 Enero 2021
					</span>
					<h3 className="text-2xl text-white font-bold">
						8 reglas generales en Diseño UI
					</h3>
					<Link to={link} activeClassName="font-bold text-white" className="text-blue-900 text-sm">
						Conoce más
					</Link>
				</div>
				<div className="col-span-1 lg:col-span-3 p-12 flex flex-col justify-center items-center">
					<div className="rounded-full items-center justify-center h-4 w-4 bg-green-900" />
				</div>
			</div>
		</div>
	)
}

export default LastPost