import React, { useState } from "react";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Form = (props) => {

  const handleChange = (e, setState) => {
    setState(e.target.value);
  };

  const [notification, setNotification] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    if (email === "") {
      setNotification(`Tu correo es requerido, a través de el te contactaremos inicialmente.`);
      return
    } else if (message === "") {
      setNotification(`¿Cómo te gustaría que te apoyemos? ¡Cúentanos un poco sobre ti!`);
      return
    } else if (name === "") {
      setNotification(`¿Cómo te llamas? ¡Queremos saber tu nombre!`);
      return
    }

    let result = await executeRecaptcha("homepage");

    let data = { email: email, name: name, comment: message, extras: {token: result}, token: result }
    console.log(data)
    fetch("https://pentcloud-website-backend.vercel.app/public/v1/contacts", {
      method: "POST",
      headers: {
        "content-type": 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())
    .then((d) => {
      setNotification('Gracias por escribirnos, en breve uno de nuestros agentes te estará contactando.'); //--> dynamically set your notification state via the server
      console.log(d)
    }, (e) =>{
      setNotification('Hubo un error, por favor verifica tus datos.'); //--> dynamically set your notification state via the server
      console.log(e)
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-center py-12">
        <h2 className="text-5xl text-gray font-bold leading-snug mb-4">
          Contáctanos
        </h2>
        <span className="text-center text-sm text-gray-400">
          ¿Tienes un proyecto en mente que crees que sería ideal para nosotros?
          Nos encantaría saber en qué estás pensando.
        </span>
      </div>
      <div className="grid grid-cols-2 gap-8 grid-flow-row">
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center align-start">
          <Input id="formName" placeholder="Ingrese su nombre aquí" label="Nombre" type="text" onChange={(e) => {handleChange(e, setName)}} value={name} required />
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center align-start">
          <Input id="formEmail" placeholder="Ingrese su dirección de correo aquí" label="Correo electrónico" type="email" onChange={(e) => {handleChange(e, setEmail)}} value={email} required />
        </div>
        <div className="col-span-2 flex flex-col justify-center align-start">
          <TextArea id="formMsg" placeholder="Escriba su mensaje aquí" label="¡Cuéntanos algo!" type="email" onChange={(e) => {handleChange(e, setMessage)}} value={message} required />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-12">
        {/* <Button classes="px-8 py-4 bg-green-900 text-white rounded-lg mr-8" type="submit" value="Send">
                    Enviar mensaje
                </Button> */}
        <input type="submit" value="Enviar" className="text-center px-8 py-4 bg-green-900 text-white rounded-lg w-36 mb-8" />

        {notification && (
          <span className="text-center text-base text-gray-400">
            {notification}
          </span>
        )}
      </div>
    </form>
  );
};

export default Form;
